export enum Gender {
    MALE = 'Κος',
    FEMALE = 'Κα',
}

export class Member {
    salutation: Gender;
    firstName = '';
    lastName = '';
    mobileNumber: number = null;
    email = '';

    dayOfBirth: number = null;
    monthOfBirth: number = null;
    yearOfBirth: number = null;

    region = '';
    town = '';
    postCode: number = null;

    acceptedTerms = false;

    constructor() {}
}

import { Component, Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import { termsMockSeventeen, termsMockLorvenn, termsMockRadiant } from 'src/app/mocks/termsOfService.mock';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent {
  termsAndConditions?: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    switch (data.schemaCode){
      case '0000': this.termsAndConditions = termsMockSeventeen; break;
      case '0001': this.termsAndConditions = termsMockRadiant; break;
      case '0002': this.termsAndConditions = termsMockLorvenn; break;
      default: this.termsAndConditions = ''; 
    }
  }
}

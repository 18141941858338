<mat-label>Ημερομηνία γέννησης</mat-label>
<div fxLayout="row"  fxLayoutGap="30px" style="margin-top: -7px;">
    <mat-form-field appearance="standard" fxFlex="20">
        <mat-label>Ημέρα</mat-label>
        <mat-select>
            <mat-option value="null">καμία επιλογή</mat-option>
            <mat-option *ngFor="let day of days" value="{{day}}">{{day}}</mat-option>
        </mat-select>
    </mat-form-field>
    
    <mat-form-field appearance="standard" fxFlex="40">
        <mat-label>Μήνας</mat-label>
        <mat-select>
            <mat-option value="null"></mat-option>
            <mat-option *ngFor="let month of months" value="{{month}}">{{month}}</mat-option>
        </mat-select>
    </mat-form-field>
    
    <mat-form-field appearance="standard" fxFlex="30" style="width: 10%;">
        <mat-label>Έτος</mat-label>
        <mat-select>
            <mat-option value="null"></mat-option>
            <mat-option *ngFor="let year of years" value="{{year}}">{{year}}</mat-option>
        </mat-select>
    </mat-form-field>
</div>
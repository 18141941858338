import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-registration-complete-page',
  templateUrl: './registration-complete-page.component.html',
  styleUrls: ['./registration-complete-page.component.css']
})
export class RegistrationCompletePageComponent implements OnInit {

  schemaCode: string;
  constructor( private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.schemaCode = params.schemaCode;
    });
   }

  ngOnInit(): void {
  }

}

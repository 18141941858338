import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameFormat'
})
export class NameFormatPipe implements PipeTransform {

  transform(value: string): string {
    if (!value){
      return null;
    }

    // Split value into several chunks by ' ', '.' or '-'
    const splitValue: string[] =  value.split(/[\s-.]+/);

    // Collect separators (' ', '.', '-')
    const separators: string[] = [];
    for (const char of value){
      if (char === ' ' || char === '-' || char === '.'){
        separators.push(char);
      }
    }

    let transformedValue = '';
    for (let i = 0; i < splitValue.length; i++){
      // Lowerize all letters
      splitValue[i] = splitValue[i].toLowerCase();

      // Capitalize first letter
      splitValue[i] = splitValue[i].charAt(0).toUpperCase() + splitValue[i].slice(1);

      // Replace all 'ς' with 'σ'
      splitValue[i] =  splitValue[i].replace('ς', 'σ');

      // Replace last 'σ' with 'ς' if exist
      if (splitValue[i].charAt(splitValue[i].length - 1) === 'σ'){
        splitValue[i] = splitValue[i].slice(0, -1) + 'ς';
      }

      // Appent transformedValue
      transformedValue += splitValue[i] + (separators[i] ? separators[i] : '');
    }

    return transformedValue;
  }
}

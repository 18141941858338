<app-loading-screen *ngIf="loading">
    Παρακαλώ περιμένετε
</app-loading-screen>
<div *ngIf="!loading"  fxLayout="column" fxLayoutAlign="center center" style="padding: 25px;">
    <div fxLayout="column" fxLayoutAlign="space-evenly center" class="logo-container">
        <img *ngIf="schemaCode === '0000'" src="./assets/images/seventeen.png" class="schema-logo">
        <img *ngIf="schemaCode === '0001'" src="./assets/images/radiant.png" class="schema-logo">
        <img *ngIf="schemaCode === '0002'" src="./assets/images/lorvenn.png" class="schema-logo">
    </div>
    
    <h2>Ολοκλήρωση εγγραφής</h2>
    <form class="container" fxLayout="column" fxLayoutAlign="space-evenly stretch" [formGroup]="memberRegistrationForm" (ngSubmit)="onSubmit()">   
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="0"  fxLayoutGap="40px">
            <mat-form-field appearance="standard" >
                <mat-label>Κινητό</mat-label>
                <input matInput disabled value="{{mobileNumber}}">
            </mat-form-field>
            <mat-form-field appearance="standard" required>
                <mat-label>Προσφώνηση *</mat-label>
                <mat-select color="primary" formControlName="salutation" >
                    <mat-option *ngFor="let gender of Gender | keyvalue" value="{{gender.key}}">{{gender.value}}</mat-option>
                </mat-select>
                <mat-error>Η Προσφώνηση είναι <strong>υποχρεωτική</strong></mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="0" fxLayoutGap="40px" >
            <mat-form-field appearance="standard">
                <mat-label>Όνομα *</mat-label>
                <input  #name
                        type="text"
                        matInput
                        formControlName="name"
                        [value]="memberRegistrationForm.get('name').value | nameFormat"
                        autocomplete="off">
                <mat-error *ngIf="nameFormControl.hasError('required')">
                    Το όνομα είναι <strong>υποχρεωτικό</strong>
                </mat-error>
                <mat-error *ngIf="nameFormControl.hasError('pattern')">
                Παρακαλώ εισάγετε ένα <strong>έγκυρο</strong> όνομα
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="standard">
                <mat-label>Επώνυμο *</mat-label>
                <input  #surname
                        type="text"
                        matInput
                        formControlName="surname" 
                        [value]="memberRegistrationForm.get('surname').value | nameFormat"
                        autocomplete="off">
                <mat-error *ngIf="surnameFormControl.hasError('required')">
                    Το επώνυμο είναι <strong>υποχρεωτικό</strong>
                </mat-error>
                <mat-error *ngIf="surnameFormControl.hasError('pattern')">
                Παρακαλώ εισάγετε ένα <strong>έγκυρο</strong> επώνυμο
                </mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="0" fxLayoutGap="40px" >
            <mat-form-field appearance="standard">
                <mat-label >Email</mat-label>
                <input #email
                        matInput
                        autocomplete="off"
                        type="email"
                        formControlName="email"
                        (input)="email.value = email.value.toLowerCase()">
                <mat-error *ngIf="emailFormControl.hasError('required')">
                    To email είναι <strong>υποχρεωτικό</strong>
                </mat-error>
                <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                    Παρακαλώ εισάγετε ένα <strong>έγκυρο</strong> email
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="standard">
                <mat-label>Νομός *</mat-label>
                <mat-select #region
                            type="text"
                            matInput
                            formControlName="region"
                            (selectionChange)="onRegionChange()">
                        <mat-option *ngFor="let reg of regions" [value]="reg.text">{{reg.text}}</mat-option>
                </mat-select>
                <mat-error *ngIf="regionFormControl.hasError('required')">
                    Ο νομός είναι <strong>υποχρεωτικός</strong>
                </mat-error>
            </mat-form-field>
        </div>
        
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="0" fxLayoutGap="40px" >
            <mat-form-field appearance="standard">
                <mat-label>Πόλη *</mat-label>
                <input #town
                        matInput
                        autocomplete="off"
                        formControlName="town"
                        (input)="town.value = town.value.toUpperCase(); fetchTowns()"
                        [matAutocomplete]="townList">
                <mat-autocomplete townList #townList="matAutocomplete">
                    <mat-option *ngFor="let tn of towns" [value]="tn">
                        {{tn}}
                    </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="regionFormControl.hasError('required')">
                    Η πόλη είναι <strong>υποχρεωτική</strong>
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="standard">
                <mat-label>Διεύθυνση</mat-label>
                <input #address
                        matInput
                        autocomplete="off"
                        formControlName="address"
                        [matAutocomplete]="addressList"
                        (input)="address.value = address.value.toUpperCase(); fetchAddresses()">
                <mat-autocomplete addressList #addressList="matAutocomplete">
                    <mat-option *ngFor="let addr of addresses" [value]="addr">
                        {{addr}}
                    </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="addressFormControl.hasError('pattern')">
                    Παρακαλώ εισάγετε μια <strong>έγκυρη</strong> διέυθυνση
                </mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="0" fxLayoutGap="40px" >
            <mat-form-field appearance="standard" fxFlex="50">
                <mat-label>Ταχυδρομικός κώδικας</mat-label>
                <input type="number"
                    matInput
                    autocomplete="off"
                    formControlName="postCode" >
                <mat-error *ngIf="postCodeFormControl.hasError('pattern')">Παρακαλώ επιλέξτε τον 5ψήφιο ταχυδρομικό κώδικα</mat-error> 
            </mat-form-field>
            <div fxFlex="50"></div>
        </div>
        <br>
        <mat-label>Ημερομηνία γέννησης</mat-label>
        <div fxLayout="row"  fxLayoutGap="30px" style="margin-top: -7px;">
            <mat-form-field appearance="standard" fxFlex="20">
                <mat-label>Ημέρα</mat-label>
                <mat-select #dayOfBirth formControlName="dayOfBirth" (selectionChange)="onBirthDateChange()">
                    <mat-option value="null"></mat-option>
                    <mat-option *ngFor="let day of days" [value]="day">{{day}}</mat-option>
                </mat-select>
            </mat-form-field>
            
            <mat-form-field appearance="standard" fxFlex="40">
                <mat-label>Μήνας</mat-label>
                <mat-select #monthOfBirth formControlName="monthOfBirth" (selectionChange)="onBirthDateChange()">
                    <mat-option value="null"></mat-option>
                    <mat-option *ngFor="let month of months" [value]="month">{{month}}</mat-option>
                </mat-select>
            </mat-form-field>
            
            <mat-form-field appearance="standard" fxFlex="30" style="width: 10%;">
                <mat-label>Έτος</mat-label>
                <mat-select #yearOfBirth formControlName="yearOfBirth" (selectionChange)="onBirthDateChange()">
                    <mat-option value="null"></mat-option>
                    <mat-option *ngFor="let year of years" [value]="year">{{year}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <input [hidden]="true" #birthDate matInput formControlName="birthDate">
        <mat-error *ngIf="submitted && birthDateFormControl.hasError('dateInvalid')" style="font-size: 10px; margin-top: -15px">
            Παρακαλώ εισάγετε μια <strong>έγκυρη</strong> ημερομηνία γέννησης
        </mat-error>

        <br>
        <h3>Όροι και προϋποθέσεις</h3>
        <br>

        <small>
            <mat-checkbox color="primary" (change)="onAcceptTermsChange($event)">
                Έχω διαβάσει και αποδέχομαι τους &nbsp;
            </mat-checkbox>
            <a (click)="onShowTerms()">όρους και προϋποθέσεις</a>
            <br>
            <mat-error *ngIf="submitted && !terms">
                Δεν έχετε <strong>αποδεχτεί</strong> τους όρους και προϋποθέσεις
            </mat-error>
        </small>
        <br>
        <small>
            <mat-checkbox color="primary" checked="true" #acceptedConsents formControlName="acceptedConsents" (change)="onConsentsChange($event)">
                Θέλω να ενημερώνομαι για τα νέα και 
            </mat-checkbox>
            τις προσφορές του προγράμματος
        </small>
        <br>

        <br><br>
        <div fxLayout="row" fxLayoutAlign="center center">
            <button mat-raised-button color="primary" type="submit" [disabled]="saving" style="min-width: 150px;">
                Εγγραφή
                <img *ngIf="saving" height="40px" src="./assets/images/Spinner-1s-200px.gif" style="margin-right: -15px;">
            </button>
        </div>
    </form>
</div>
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor(private http: HttpClient) { }

  getRegions(): Observable<any>{
    const endpoint = `${environment.publicApiURL}/rest/public/form/region?CountryCode=gr&lang=el`;
    return this.http.get<any>(endpoint);
  }

  getTowns(regionCode: string, cityTerm: string): Observable<any>{
    const endpoint = `${environment.publicApiURL}/rest/public/form/city?CountryCode=gr&lang=el&RegionCode=${regionCode}&CityTerm=${cityTerm}`;
    return this.http.get<any>(endpoint);
  }

  getAddresses(regionCode: string, cityTerm: string, streetTerm: string): Observable<any>{
    const endpoint = `${environment.publicApiURL}/rest/public/form/street?CountryCode=gr&lang=el&RegionCode=${regionCode}&StreetTerm=${streetTerm}&CityTerm=${cityTerm}`;
    return this.http.get<any>(endpoint);
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AlreadyRegisteredComponent } from './view/pages/already-registered/already-registered.component';
import { ExpiredPageComponent } from './view/pages/expired-page/expired-page.component';
import { RegistrationCompletePageComponent } from './view/pages/registration-complete-page/registration-complete-page.component';
import { RegistrationFormPageComponent } from './view/pages/registration-form-page/registration-form-page.component';

const routes: Routes = [
  { path: 'already-registered', component: AlreadyRegisteredComponent, pathMatch: 'full' },
  { path: 'registration-complete', component: RegistrationCompletePageComponent, pathMatch: 'full' },
  { path: 'registration-expired', component: ExpiredPageComponent, pathMatch: 'full' },
  { path: '**', component: RegistrationFormPageComponent, pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }

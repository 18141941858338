import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-register-status-modal',
  templateUrl: './register-status-modal.component.html',
  styleUrls: ['./register-status-modal.component.css']
})
export class RegisterStatusModalComponent implements OnInit {
  success: boolean = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: {success: boolean}) {
    this.success = data.success;
  }

  ngOnInit(): void {
  }

}

import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[appIncludedInListValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: IncludedInListValidatorDirective, multi: true}]
})
export class IncludedInListValidatorDirective implements Validator{

  @Input('appIncludedInListValidator') control: string;

  validate(control: AbstractControl): ValidationErrors | null {
    const d = new Date(control.value);
    if (d instanceof Date) {
      return { includedInListValid: true };
    }
    return null;
  }
}

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmptyResponse } from 'src/app/models/http-service-models/common.body.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  getBearerToken(authToken: string) {
    const endpoint = `${environment.coreApiURL}/public/customers/jwt/${authToken}`;
    return this.http.get<HttpResponse<EmptyResponse>>(endpoint, {observe: 'response'});
  }
}

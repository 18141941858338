
import { Component } from '@angular/core';
import { Store } from './models/store.model';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from './services/auth/auth.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'hellenica-registration';
  store: Store = {
    name: '',
    code: ''
  };

  constructor(private router: Router, private route: ActivatedRoute, private auth: AuthService) {

    
  }

  ngOnInit() {
   
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { HttpConfigInterceptor } from './interceptors/httpconfig.interceptor';

import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RegistrationFormPageComponent } from './view/pages/registration-form-page/registration-form-page.component';
import { TermsAndConditionsComponent } from './view/components/terms-and-conditions/terms-and-conditions.component';
import { BirthdayComponent } from './view/components/birthday/birthday.component';
import { AppRoutingModule } from './app-routing.module';
import { EnumToArrayPipe } from './pipes/enum-to-array/enum-to-array.pipe';
import { NameFormatPipe } from './pipes/name-format/name-format.pipe';
import { IncludedInListValidatorDirective } from './validators/included-in-list-validator/included-in-list-validator.directive';
import { FlexLayoutModule } from '@angular/flex-layout';



// Angular material modules ---------------------------------------
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
// ----------------------------------------------------------------

import { NgOtpInputModule } from 'ng-otp-input';
import { LoadingScreenComponent } from './view/components/loading-screen/loading-screen.component';
import { ExpiredPageComponent } from './view/pages/expired-page/expired-page.component';
import { RegistrationCompletePageComponent } from './view/pages/registration-complete-page/registration-complete-page.component';
import { AlreadyRegisteredComponent } from './view/pages/already-registered/already-registered.component';
@NgModule({
  declarations: [
    AppComponent,
    RegistrationFormPageComponent,
    BirthdayComponent,
    TermsAndConditionsComponent,
    EnumToArrayPipe,
    NameFormatPipe,
    IncludedInListValidatorDirective,
    LoadingScreenComponent,
    ExpiredPageComponent,
    RegistrationCompletePageComponent,
    AlreadyRegisteredComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
   
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatTabsModule,
    NgOtpInputModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
  ],
  providers: [
    
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-birthday',
  templateUrl: './birthday.component.html',
  styleUrls: ['./birthday.component.css']
})
export class BirthdayComponent {

  days: number[];
  months: string[];
  years: number[];

  constructor() {
    // this.days   = Array(31).fill(1).map((x, i) => i + 1); // [1 to 31]
    // this.months = Months;
    // this.years  = Array(85).fill(1).map((x, i) => (2021 - 18) - i); // [currentYear-18 to currentYear-85]
  }
}

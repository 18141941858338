import { Component, OnInit } from '@angular/core';
import { Schema } from 'src/app/models/schema.model';
import { schemasMock } from 'src/app/mocks/schemas.mock';
import { MatDialog } from '@angular/material/dialog';
import { TermsAndConditionsComponent } from 'src/app/view/components/terms-and-conditions/terms-and-conditions.component';
import { AbstractControl, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Gender } from 'src/app/models/member.model';
import { validDate } from 'src/app/validators/date-validator/date-validator.directive';
import { DAYS, MONTHS, YEARS } from 'src/app/utilities/date.const';
import { RegistrationState } from 'src/app/models/schema.model';
import { RegistrationService } from 'src/app/services/registration/registration.service';
import { PersonResponse } from 'src/app/models/http-service-models/create-member.body.model';
import { RegisterStatusModalComponent } from 'src/app/view/components/register-status-modal/register-status-modal.component';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { UpdatePersonRequest } from 'src/app/models/http-service-models/update-person.body.model';
import { forkJoin, Observable } from 'rxjs';
import { UtilityService } from '../../../services/utility/utility.service';
import { Attribute } from 'src/app/models/attribute.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Address, Consent, Email, LoyaltyMembership, Telephone } from 'src/app/models/http-service-models/common.body.model';

import jwt_decode from "jwt-decode";
import { formatDate } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-registration-form-page',
  templateUrl: './registration-form-page.component.html',
  styleUrls: ['./registration-form-page.component.css']
})
export class RegistrationFormPageComponent implements OnInit{
  days = DAYS;
  months = MONTHS;
  years = YEARS;
  regions = [];
  towns = [];
  addresses = [];
  Gender = Gender;
  memberRegistrationForm: FormGroup = new FormGroup({});
  mobileNumber: string;
  schemaCode: string;
  matcher = new MyErrorStateMatcher();
  submitted = false;
  loading = true;
  saving = false;
  memberQCCode: string;
  personQCCode: string;
  emailQCCode: string;
  addressQCCode: string;
  personConsents: Consent[];
  consent = true;
  terms = false;

  constructor(public dialog: MatDialog,
              private router: Router,
              private route: ActivatedRoute,
              private auth: AuthService,
              private registrationService: RegistrationService,
              private utilityServide: UtilityService,
              private snackBar: MatSnackBar) {

    this.route.queryParams.subscribe(params => {
      if(!params.token){
        this.router.navigate(['/registration-expired']);
      }

      this.memberQCCode = params.code;

      if(!sessionStorage.getItem('x-m-jwt-token') ||
          sessionStorage.getItem('x-m-jwt-token') === 'undefined'){
            this.auth.getBearerToken(params.token).subscribe(
              success => {
                sessionStorage.setItem('x-m-jwt-token', success.headers.get('x-m-jwt-token'));

                if (sessionStorage.getItem('x-m-jwt-token') === 'undefined'){
                  this.goToRegistrationExpiredPage();
                }

                this.init();
              },
              error => {
                this.goToRegistrationExpiredPage();
              });
      }
      else{
        this.init();
      }
    });
  }

  ngOnInit(): void {
    this.init();
  }

  init(): void {
    this.utilityServide.getRegions()
    .subscribe((success) => {
      this.regions = success.lookupEntityList;
      this.initForm();
      this.fillForm();
    });
  }

  // Form control getters -------------------------------------------------------------------------------------------
  get salutationFormControl(): AbstractControl | null   { return this.memberRegistrationForm.get('salutation');       }
  get nameFormControl(): AbstractControl | null         { return this.memberRegistrationForm.get('name');             }
  get surnameFormControl(): AbstractControl | null      { return this.memberRegistrationForm.get('surname');          }
  get emailFormControl(): AbstractControl | null        { return this.memberRegistrationForm.get('email');            }
  get regionFormControl(): AbstractControl | null       { return this.memberRegistrationForm.get('region');           }
  get townFormControl(): AbstractControl | null         { return this.memberRegistrationForm.get('town');             }
  get addressFormControl(): AbstractControl | null      { return this.memberRegistrationForm.get('address');          }
  get postCodeFormControl(): AbstractControl | null     { return this.memberRegistrationForm.get('postCode');         }
  get dayOfBirthFormControl(): AbstractControl | null   { return this.memberRegistrationForm.get('dayOfBirth');       }
  get monthOfBirthFormControl(): AbstractControl | null { return this.memberRegistrationForm.get('monthOfBirth');     }
  get yearOfBirthFormControl(): AbstractControl | null  { return this.memberRegistrationForm.get('yearOfBirth');      }
  get birthDateFormControl(): AbstractControl | null    { return this.memberRegistrationForm.get('birthDate');        }
  get acceptedConsentsControl(): AbstractControl | null { return this.memberRegistrationForm.get('acceptedConsents'); }
  // ----------------------------------------------------------------------------------------------------------------

  // Event Handlers -------------------------------------------------------------------------------------------------
  onSubmit(): void {
    this.submitted = true;
    if (this.memberRegistrationForm.valid && this.terms){
      this.saving = true;

      const actions: Observable<any>[] = [];
      const person: UpdatePersonRequest = this.getUpdatePersonRequestBody();
      actions.push(this.registrationService.updatePerson(this.personQCCode, person));
      actions.push(this.registrationService.updateLoyaltyMembership(this.personQCCode, this.memberQCCode, this.getUpdateLoyaltyRequestBodys()));

      forkJoin(actions)
        .subscribe(
          (successes) => {
            this.saving = false;
            this.goToRegistrationCompletePage();
          },
          (error: HttpErrorResponse) => {
              this.saving = false;
              this.dialog.open(RegisterStatusModalComponent, {data: {success: false}});
          });
    }
  }

  onShowTerms(): void {
    this.dialog.open(TermsAndConditionsComponent, {
       data: {schemaCode: this.schemaCode }
    });
  }

  onAcceptTermsChange($event: any): void {
    this.terms = $event.checked;
  }

  onConsentsChange($event: any): void {
    this.consent = $event.checked;
  }

  onBirthDateChange(): void {
    const day   = this.dayOfBirthFormControl.value;
    const month = this.months.indexOf(this.monthOfBirthFormControl.value) + 1;
    const year  = this.yearOfBirthFormControl.value;
    this.birthDateFormControl.setValue(day  + '/' + month + '/' + year);
  }

  onRegionChange(): void {
    this.townFormControl.setValue('');
    this.addressFormControl.setValue('');

    this.fetchTowns();
  }
  // ----------------------------------------------------------------------------------------------------------------
  fetchRegions(): void {
    this.utilityServide.getRegions()
      .subscribe((success) => {
        this.regions = success.lookupEntityList;
      });
  }

  fetchTowns(): void {
    this.utilityServide.getTowns(this.getRegionCode(this.regionFormControl.value), this.townFormControl.value)
      .subscribe(success => {
        this.towns = success.stringList;
      });
  }

  fetchAddresses(): void {
    this.utilityServide.getAddresses(this.getRegionCode(this.regionFormControl.value), this.townFormControl.value, this.addressFormControl.value)
      .subscribe(success => {
        this.addresses = success.stringList;
      });
  }
  // ----------------------------------------------------------------------------------------------------------------

  private initForm(): void {
    this.memberRegistrationForm = new FormGroup({
      salutation: new FormControl('', [
        Validators.required,
      ]),
      name: new FormControl('', [
        Validators.pattern(/^[α-ωίϊΐόάέύϋΰήώΑ-ΩΆΈΊΌΎΏΉa-zA-Z\s.-]+$/), // Accept latin & Greek letters, ' ', '.', '-'
        Validators.required,
      ]),
      surname: new FormControl('', [
        Validators.pattern(/^[α-ωίϊΐόάέύϋΰήώΑ-ΩΆΈΊΌΎΏΉa-zA-Z\s.-]+$/), // Accept latin & Greek letters, ' ', '.', '-'
        Validators.required,
      ]),
      email: new FormControl('', [
        Validators.email,
      ]),
      region: new FormControl('', [
        Validators.required
      ]),
      town: new FormControl('', [
        Validators.required
      ]),
      address: new FormControl('', [
        // Accept latin & Greek letters, nubers, ' ', '&', '(', ')', ''', '-'
        Validators.pattern(/^[α-ωίϊΐόάέύϋΰήώΑ-ΩΆΈΊΌΎΏΉa-zA-Z0-9\s()&/'.-]+$/),
      ]),
      postCode: new FormControl('', [
        Validators.pattern('^$|^([0-9][0-9][0-9][0-9][0-9])?') // Accept only 5 digits
      ]),
      dayOfBirth: new FormControl('', [
      ]),
      monthOfBirth: new FormControl('', [
      ]),
      yearOfBirth: new FormControl('', [
      ]),
      birthDate: new FormControl('', [
        validDate
      ]),
      acceptedConsents: new FormControl('', [
      ])
    });
  }

  private fillForm(): void{
    const jwtData = jwt_decode<any>(sessionStorage.getItem('x-m-jwt-token'));
    this.memberQCCode = jwtData?.memberQCCode;
    this.mobileNumber = jwtData?.mobileNumber;
    this.acceptedConsentsControl.setValue(true);

    this.registrationService.getPersonDetails(this.memberQCCode)
      .subscribe((success: PersonResponse) => {
        
        if (success.payload.data.length < 1){
          return;
        }

        const personDetails = success.payload.data[0];
        this.schemaCode = personDetails?.loyaltyMembershipData.find(lmd => lmd.QCCode === this.memberQCCode)?.schemaCode;

        if(this.isMemberRegistered(personDetails.loyaltyMembershipData)){
          this.goToAlreadyRegisteredPage();
          return;
        }

        const email = personDetails.emailList.find(e => e.isPrimary === true);
        const address = personDetails.addressList.find(e => e.addressType === 'PRIMARY_PERSON_ADDRESS');
        this.personQCCode = personDetails?.QCCode;
        this.emailQCCode = email?.QCCode;
        this.addressQCCode = address?.QCCode;

        this.salutationFormControl.setValue(personDetails.salutationCode);
        this.nameFormControl.setValue(personDetails.firstName);
        this.surnameFormControl.setValue(personDetails.lastName);
        this.emailFormControl.setValue(email?.emailAddress);
        this.addressFormControl.setValue(address?.addressLine2);
        this.townFormControl.setValue(address?.town);
        this.regionFormControl.setValue(this.getRegionText(address?.regionCode));
        this.postCodeFormControl.setValue(address?.postCode);
        if(address?.regionCode){
          this.fetchTowns();
        }
        if(address?.town){
          this.fetchAddresses();
        }

        let bdate = new Date(personDetails?.dateOfBirth?.substring(0,10));
        this.dayOfBirthFormControl.setValue(bdate?.getDate());
        this.monthOfBirthFormControl.setValue(this.months[bdate?.getMonth()]);
        this.yearOfBirthFormControl.setValue(bdate?.getFullYear());
        this.birthDateFormControl.setValue(bdate?.getDate() + '/' + bdate?.getMonth() + '/' + bdate?.getFullYear());

        this.personConsents = personDetails?.consentList;

        this.loading = false;
      },
      (error: HttpErrorResponse) => {
        this.loading = false;
        this.showSnackBarMessage('Παρουσιάστηκε σφάλμα κατά την φόρτωση δεδομένων χρήστη');
      });
  }

  private getUpdatePersonRequestBody(): UpdatePersonRequest {
    const addressModifications = [];
    let addressAdditions = [];
    if (this.addressQCCode){
      addressModifications.push({
        addressLine2: {
          value: this.addressFormControl.value?.toUpperCase()
        },
        town: {
          value: this.townFormControl.value?.toUpperCase()
        },
        regionCode: {
          value: this.getRegionCode(this.regionFormControl.value)
        },
        postCode: {
          value: this.postCodeFormControl.value
        },
        QCCode: this.addressQCCode,
        action: 'UPDATE'
      });
    }
    else{
      if (this.addressFormControl.value || this.townFormControl.value || this.regionFormControl.value || this.postCodeFormControl.value){
        addressAdditions = this.getAddresses();
      }
    }

    const emailModifications = [];
    let emailAdditions = [];
    if (this.emailQCCode){
      emailModifications.push({
        emailAddress: {
          value: this.emailFormControl.value
        },
        isPrimary: {
            value: true
        },
        QCCode: this.emailQCCode,
        action: 'UPDATE'
      });
    }
    else{
        emailAdditions = this.getEmails();
    }

    let consentModifications = [];
    let consentAdditions =[];
    if (this.personConsents.find(c => c.name.includes(this.getSchemaName()))){
     consentModifications = [
      {
        consentName: 'SMS_' + this.getSchemaName(),
        flag: {
          value: this.consent
        },
        metadata: [
          {
            key: 'Source',
            value: 'MOBILE_APP'
          }
        ],
        action: 'UPDATE'
      },
      {
        consentName: 'VIBER_' + this.getSchemaName(),
        flag:{
          value: this.consent
        },
        metadata: [
          {
            key: 'Source',
            value: 'MOBILE_APP'
          }
        ],
        action: 'UPDATE'
      },
      {
        consentName: 'EMAIL_' + this.getSchemaName(),
        flag: {
          value: this.consent
        },
        metadata: [
          {
            key: 'Source',
            value: 'MOBILE_APP'
          }
        ],
        action: 'UPDATE'
      }
    ];
    }
    else{
      consentAdditions = this.getConsents();
    }
    

    return {
      firstName: {
        value: this.nameFormControl.value
      },
      lastName: {
        value: this.surnameFormControl.value
      },
      dateOfBirth: {
        value: this.getBirthDate()
      },
      gender: {
        value: this.salutationFormControl.value
      },
      salutationCode: {
        value: this.salutationFormControl.value
      },
      addresses: {
        modifications: addressModifications,
        additions: addressAdditions
      },
      emails: {
        modifications: emailModifications,
        additions: emailAdditions
      },
      consents: {
        modifications: consentModifications,
        additions: consentAdditions
      }
    };
  }

  private getUpdateLoyaltyRequestBodys(): Attribute[] {
    return [
      {
        attributeName: this.getSchemaName() + '_TERMS',
        attributeValue: true,
        dataType: 'BOOLEAN'
      }
    ];
  }

  private getAddresses(): Address[] {
    const addresses: Address[] = [];
    if (this.addressFormControl.value || this.townFormControl.value || this.regionFormControl.value || this.postCodeFormControl.value){
      addresses.push({
        addressLine2: this.addressFormControl.value?.toUpperCase(),
        countryCode: 'gr',
        postCode: this.postCodeFormControl.value,
        regionCode: this.getRegionCode(this.regionFormControl.value),
        town: this.townFormControl.value?.toUpperCase(),
        addressType: 'PRIMARY_PERSON_ADDRESS'
      });
    }
    return addresses;
  }

  private getEmails(): Email[]{
    const emails: Email[] = [];
    if (this.emailFormControl.value){
      emails.push({
          emailAddress: this.emailFormControl.value,
          isPrimary: true
      });
    }
    return emails;
  }

  private getConsents() : Consent[] { 
    return[
      {
        name: 'SMS_' + this.getSchemaName(),
        flag: this.consent,
        metadata: [
          {
            key: 'Source',
            value: 'MOBILE_APP'
          }
        ]
      },
      {
        name: 'VIBER_' + this.getSchemaName(),
        flag: this.consent,
        metadata: [
          {
            key: 'Source',
            value: 'MOBILE_APP'
          }
        ]
      },
      {
        name: 'EMAIL_' + this.getSchemaName(),
        flag: this.consent,
        metadata: [
          {
            key: 'Source',
            value: 'MOBILE_APP'
          }
        ]
      }
    ];
  }

  private getBirthDate(): string {
    if((!this.dayOfBirthFormControl.value   && 
        !this.monthOfBirthFormControl.value &&
        !this.yearOfBirthFormControl.value) || 
       (this.dayOfBirthFormControl.value  === 'null' &&
       this.monthOfBirthFormControl.value === 'null' &&
       this.yearOfBirthFormControl.value  === 'null')){
      return null;
    }

    let birthDate = new Date(this.yearOfBirthFormControl.value,
                             this.months.indexOf(this.monthOfBirthFormControl.value),
                             this.dayOfBirthFormControl.value)
                             .toISOString();

    return formatDate(birthDate, 'yyyy-MM-ddTHH:mm:ss.SSSZ', 'en-US');
  }

  private getRegionCode(regionText): string {
    return this.regions?.find(r => r.text === regionText)?.code;
  }

  private getRegionText(regionCode): string {
    return this.regions?.find(r => r.code === regionCode)?.text;
  }

  private getSchemaName(): string {
    let schemaName = '';
    switch (this.schemaCode){
      case '0000': schemaName = 'SEVENTEEN'; break;
      case '0001': schemaName = 'RADIANT'; break;
      case '0002': schemaName = 'LORVENN'; break;
      default: schemaName = '';
    }

    return schemaName;
  }

  private isMemberRegistered(loyaltyMembershipData: LoyaltyMembership[]): boolean {
    const loyaltyMembership = loyaltyMembershipData?.find(lmd => lmd.schemaCode === this.schemaCode);
    const acceptedTermsAttr = loyaltyMembership.attributes.find(attr => attr.attributeName.includes('TERMS'));
    return JSON.parse(acceptedTermsAttr.attributeValue);
  }

  private goToRegistrationCompletePage(): void {
    this.router.navigate(['registration-complete'], {queryParams: {schema: this.schemaCode }, queryParamsHandling: 'preserve'});
  }

  private goToRegistrationExpiredPage(): void {
    this.router.navigate(['registration-expired'], {queryParams: {schema: this.schemaCode }, queryParamsHandling: 'preserve'});
  }

  private goToAlreadyRegisteredPage(): void {
    this.router.navigate(['already-registered'], {queryParams: {schema: this.schemaCode }});
  }

  private showSnackBarMessage(message, duration = 2000): void {
    this.snackBar.open(message, null, {
      duration: duration,
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-already-registered',
  templateUrl: './already-registered.component.html',
  styleUrls: ['./already-registered.component.css']
})
export class AlreadyRegisteredComponent implements OnInit {

  schemaCode: string;
  constructor( private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.schemaCode = params.schema;
    });
   }

  ngOnInit(): void {
  }

}

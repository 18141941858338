<div fxLayout="column" fxLayoutAlign="space-evenly center" class="logo-container">
    <img *ngIf="schemaCode === '0000'" src="./assets/images/seventeen.png" class="schema-logo">
    <img *ngIf="schemaCode === '0001'" src="./assets/images/radiant.png" class="schema-logo">
    <img *ngIf="schemaCode === '0002'" src="./assets/images/lorvenn.png" class="schema-logo">
</div>

<div class="full-screen-80" fxLayout="column" fxLayoutAlign="center center" >
    <img src="./assets/images/tick-mark.svg" width="70%" style="max-width:350px">
    <br>
    <h2 >Η εγγραφή ολοκληρώθηκε</h2>
</div>

<small style="position: absolute; bottom: 0; right: 5px;">
    <div>Icons made by <a href="https://www.flaticon.com/authors/pixel-perfect" title="Pixel perfect">Pixel perfect</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
</small>
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Attribute } from 'src/app/models/attribute.model';
import { LoyaltyMembership } from 'src/app/models/http-service-models/common.body.model';
import { UpdatePersonRequest } from 'src/app/models/http-service-models/update-person.body.model';
import { environment } from 'src/environments/environment';
import { CreatePersonRequest, PersonResponse } from '../../models/http-service-models/create-member.body.model';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  constructor(private http: HttpClient) { }

  getPersonDetails(memberQCCode: string): Observable<PersonResponse>{
    const endpoint = `${environment.coreApiURL}/protected/persons/search`;

    const body = {
      criteriaList: [
        {
          criteriaType: 'LOYALTY_MEMBERSHIP_QCCODE',
          code: memberQCCode
        }
      ],
      pagination: {
        page: 1,
        pageSize: 100
      },
      sorting: {
        sortingField: 'ID',
        sortingOrder: 'DESC'
      }
    };

    return this.http.post<PersonResponse>(endpoint, body);
  }

  createPerson(person: CreatePersonRequest): Observable<PersonResponse>{
    const endpoint = `${environment.coreApiURL}/protected/persons`;
    return this.http.post<PersonResponse>(endpoint, person);
  }

  updatePerson(qcCode: string, person: UpdatePersonRequest): Observable<PersonResponse>{
    const endpoint = `${environment.coreApiURL}/protected/persons/${qcCode}/multidata-action`;
    return this.http.put<PersonResponse>(endpoint, person);
  }

  updateLoyaltyMembership(personQCCode, loyaltyQCCode, loyaltyMemberAttributes: Attribute[]) {
    const endpoint = `${environment.coreApiURL}/protected/persons/${personQCCode}/loyalty-membership/${loyaltyQCCode}`;
    return this.http.put<any>(endpoint, 
      {
        attributes: loyaltyMemberAttributes
      }
    );
  }
}
